import styled from "styled-components";
import {Container} from "../shared";
import image1 from "./gallery-bhp-2@2x.png";
import image2 from "./gallery-asic@2x.png";
import image3 from "./gallery-cigna@2x.png";
import image4 from "./gallery-perpetual@2x.png";
import image5 from "./gallery-iag@2x.png";
import image6 from "./gallery-amp@2x.png";
import image7 from "./gallery-ansvar@2x.png";
import image8 from "./gallery-ge@2x.png";


const Root = styled.div`
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 150px;

  display: flex;
  justify-content: center;
  background-color: #424242;
`;

const Text = styled.div`
  z-index: 1000;
  font-size: 50px;
  font-weight: bold;
`;

const ImagePanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-left: -7px;
  margin-right: -7px;

  > div {
    width: 299px;
    height: 299px;
    overflow: hidden;
    border: 2px solid black;
    margin: 7px;

    img {
      margin: -7px;
      width: 314px;
      transition: all 0.6s linear;

      &:hover {
        transform: scale(1.15);
      }
    }
  }
`;

const Panel1 = () => (
  <Root>
    <Container>
      <ImagePanel>
        <div>
          <img src={image1} alt="Work Showcase" />
        </div>
        <div>
          <img src={image2} alt="Work Showcase"/>
        </div>
        <div>
          <img src={image3} alt="Work Showcase"/>
        </div>
        <div>
          <img src={image4} alt="Work Showcase"/>
        </div>
        <div>
          <img src={image5} alt="Work Showcase"/>
        </div>
        <div>
          <img src={image6} alt="Work Showcase"/>
        </div>
        <div>
          <img src={image7} alt="Work Showcase"/>
        </div>
        <div>
          <img src={image8} alt="Work Showcase"/>
        </div>
      </ImagePanel>
    </Container>
  </Root>
)

export default Panel1;
